<template>
  <FadeIn>
    <div class="tailwind">
      <div class="#flex #relative #w-full #max-w-[260px] #mb-6">
        <a
          href="javascript:"
          class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
          :class="{ '#opacity-30': form.contact_type !== 'bedrijf' }"
          @click="form.contact_type = 'bedrijf'"
        >
          <svg
            class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 266.67 149.97"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="businessclip">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
                  style="fill: none"
                />
              </clipPath>
            </defs>
            <g clip-path="url('#businessclip')">
              <path
                id="bedrijf"
                class="typecircle #stroke-brightcyan #stroke-[30px]"
                :class="getCircleClass('bedrijf')"
                d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
                style="fill: none"
              />
            </g>
          </svg>
          <p class="#relative #z-[1] #font-semibold">
            {{ __("Zakelijk") }}
          </p>
        </a>
        <a
          href="javascript:"
          class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
          :class="{ '#opacity-30': form.contact_type !== 'particulier' }"
          @click="form.contact_type = 'particulier'"
        >
          <svg
            class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 266.67 149.97"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="privateclip">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
                  style="fill: none"
                />
              </clipPath>
            </defs>
            <g clip-path="url('#privateclip')">
              <path
                id="particulier"
                class="typecircle #stroke-brightcyan #stroke-[30px]"
                :class="getCircleClass('particulier')"
                d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
                style="fill: none"
              />
            </g>
          </svg>
          <p class="#relative #z-[1] #font-semibold">
            {{ __("Particulier") }}
          </p>
        </a>
        <a
          href="javascript:"
          class="#relative #flex #justify-center #py-4 #px-4 #text-center #font-medium #text-base hover:#opacity-100"
          :class="{ '#opacity-30': form.contact_type !== 'student' }"
          @click="form.contact_type = 'student'"
        >
          <svg
            class="#absolute #top-0 #w-[110%] #h-[130%] #-mt-[10px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 266.67 149.97"
            preserveAspectRatio="none"
          >
            <defs>
              <clipPath id="studentclip">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="m156.2,11.9c-6.3.5-11.6,1.2-15.2,1.8-1.8.3-3.2.5-4.2.7-1,.2-1.5.3-1.5.3-6.7,2-13.7,4.1-20.5,6.1C117.3,14,121,.7,116.5,0c-1.9,0-10,.3-20.9,2.8-2.7.6-5.6,1.3-8.7,2.2C43.1,17.3,7.9,51.9,1.9,73.7c-7.8,28.3,9.8,50.5,38,61.9,3.2,1.3,6.5,2.4,9.6,3.4,6.3,2,12.3,3.4,17.8,4.6,9.5,2,19.3,3.7,24.6,5.2,3-.5,12,.3,24.1.8,12.1.5,27.2.7,42,0,48.9-2.6,65.3-14.8,71.8-18.4,9.5-5.3,18.4-11.5,18.4-11.5,10.7-4.7,14.9-21.4,16.9-23.9,11.8-45.5-42.8-88.7-108.8-83.7Zm79.6,72.8c-2.5,17.8-36.1,28.5-54.3,32.7-8.6,2-2.9-.2-10.8.8-9.7,1.2-19.2,2-31.6,2.2-26.2.5-94.8-3.3-106.2-26.5-1.7-3.5-1.2-7-.6-7.2.7-.2-1.2-2.8-.3-3.4,4.1-15.3,20.8-30.7,38.8-40.6-1.8,8.4.4,24.3,4.1,27,.7.5,11.8-5.1,23-10.4,11.2-5.3,22.7-9.7,23.7-10.3,1.1-.7,2.4-.9,2.9-.5,0,0,.7-.1,1.9-.4,6.7-1.5,23.7-6,40.3-5.5,0,.5,1.2.7,2.9.3,1.7-.4,6.6-.7,13.2,0,18.3,1.7,33.3,9,41.7,16.4,4.7,4.2,7.4,8.3,8.3,10.2,1.9,4.3,3.4,10.4,3.2,15.2Z"
                  style="fill: none"
                />
              </clipPath>
            </defs>
            <g clip-path="url('#studentclip')">
              <path
                id="student"
                class="typecircle #stroke-brightcyan #stroke-[30px]"
                :class="getCircleClass('student')"
                d="m124.26,10.01S22.26,26.01,16.26,88.01c-5.01,51.76,127,48,137,48s96.72-4.66,98-56c1-40-58.01-54.76-86-54-37,1-73,17-102,33"
                style="fill: none"
              />
            </g>
          </svg>
          <p class="#relative #z-[1] #font-semibold">
            {{ __("Student") }}
          </p>
        </a>
      </div>
      <div class="#flex #flex-col md:#flex-row #mt-6">
        <div class="#grow #basis-3/5 md:#pr-[20px] md:#border-r-[1px] md:#border-neutral-300">
          <div>
            <h2 class="#text-lg #font-semibold #mb-6">
              {{
                form.contact_type === "bedrijf"
                  ? __("Factuurgegevens")
                  : __("Persoonlijke gegevens")
              }}
            </h2>
            <div class="">
              <InputField
                v-if="form.contact_type === 'bedrijf'"
                v-model="form.company"
                :error="firstError('company')"
                :warning="formWarnings.company"
                class="#mb-6 #max-w-[500px]"
                :label="__('Bedrijfsnaam')"
                type="text"
                :required="true"
              />
              <InputField
                v-else
                v-model="form.name_contact"
                :error="firstError('name_contact')"
                class="#mb-6 #max-w-[500px]"
                :label="__('Naam')"
                type="text"
                :required="true"
              />

              <InputField
                v-if="form.contact_type !== 'bedrijf'"
                :model-value="form.email"
                :disabled="!isAdmin"
                :error="firstError('email')"
                class="#mb-6 #max-w-[500px]"
                :label="__('E-mailadres')"
                type="email"
                :required="true"
                @update:model-value="(value) => filterEmailAddress(value)"
              />

              <InputField
                v-if="form.contact_type !== 'bedrijf'"
                :model-value="form.telephone"
                :error="firstError('telephone')"
                class="#mb-6 #max-w-[500px]"
                :label="__('Telefoonnummer')"
                type="text"
                @update:model-value="(value) => filterPhoneNumber(value)"
              />

              <div class="#flex #items-start #gap-x-6 #max-w-[500px]">
                <InputField
                  :model-value="form.zipcode"
                  :error="firstError('zipcode')"
                  class="#mb-6"
                  size="sm"
                  :label="__('Postcode')"
                  type="text"
                  :required="true"
                  :disabled="autocompleting"
                  @update:model-value="(value) => filterZipCode(value)"
                >
                  <div
                    v-if="autocompleting"
                    class="#hidden #absolute #h-full #w-full sm:#flex #justify-end #items-center #pointer-events-none #pr-2"
                  >
                    <LoaderSpinner class="!#w-max !#mx-0" size="sm"></LoaderSpinner>
                  </div>
                </InputField>
                <InputField
                  v-model="form.city"
                  :error="firstError('city')"
                  class="#mb-6 #flex-1"
                  :disabled="autocompleting"
                  size="lg"
                  :label="__('Plaats')"
                  type="text"
                  :required="true"
                />
              </div>
              <div class="#flex #items-start #gap-x-6 #max-w-[500px]">
                <InputField
                  v-model="form.street"
                  :error="firstError('street')"
                  class="#mb-6 #flex-1"
                  :disabled="autocompleting"
                  size="lg"
                  :label="__('Straatnaam')"
                  type="text"
                  :required="true"
                />
                <InputField
                  ref="streetNumber"
                  v-model="form.streetnumber"
                  :error="firstError('streetnumber')"
                  class="#mb-6"
                  :disabled="autocompleting"
                  size="sm"
                  :label="__('Nummer')"
                  type="text"
                  :required="true"
                />
              </div>

              <InputDropdown
                v-model="form.country"
                :items="countriesForSelect"
                :error="firstError('country')"
                :label="__('Land')"
                :class="{ '#pb-6': form.contact_type === 'bedrijf' }"
                class="#max-w-[500px] #relative"
              />
              <InputField
                v-if="form.contact_type === 'bedrijf' && from_europe"
                :model-value="form.vat_number"
                class="#max-w-[500px]"
                :label="__('BTW nummer')"
                type="text"
                :input-class="vies_border"
                @update:model-value="(value) => filterVatNumber(value)"
                @blur="validateVatNumber(form.vat_number)"
              />
              <p
                v-if="vatResponse && vatResponse.error_text"
                class="#text-sm #font-semibold #mt-2 #max-w-[500px]"
                :class="vies_text"
              >
                {{ vatResponse.error_text }}
              </p>
              <InfoDropdown
                v-if="vatResponse && vatResponse.show_help"
                class="#max-w-[500px] #mt-2"
              >
                <template #info>
                  <p class="#text-sm #mb-2 #mt-2">
                    {{
                      __(
                        "Bij het invullen van het BTW nummer is het belangrijk dat deze voldoet aan alle eisen van de EU.",
                      )
                    }}
                  </p>
                  <p class="#text-sm">
                    {{
                      __(
                        "Is dat het geval? Neem dan contact op met de KVK om te controleren wat er mis is.",
                      )
                    }}
                  </p>
                  <div class="#flex #flex-wrap #gap-y-[10px] #gap-x-[15px] #mt-6">
                    <Button
                      :text="__('BTW valideren')"
                      target="_blank"
                      href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation"
                    ></Button>
                    <Button
                      :text="__('Contact KVK')"
                      href="https://www.kvk.nl/hulp-en-contact/"
                      target="_blank"
                      btn-class="primary-outline"
                    ></Button>
                  </div>
                </template>
              </InfoDropdown>

              <div v-if="form.contact_type === 'bedrijf'" class="#max-w-[500px]">
                <h2 class="#text-lg #font-semibold #mt-6 #mb-6">
                  {{ __("Contactgegevens") }}
                </h2>
                <InputField
                  v-model="form.name_contact"
                  :error="firstError('name_contact')"
                  class="#mb-6"
                  :label="__('Naam')"
                  type="text"
                  :required="true"
                />
                <InputField
                  :model-value="form.email"
                  :disabled="!isAdmin"
                  :error="firstError('email')"
                  class="#mb-6"
                  :label="__('E-mailadres')"
                  type="email"
                  :required="true"
                  @update:model-value="(value) => filterEmailAddress(value)"
                />
                <InputField
                  :model-value="form.telephone"
                  :error="firstError('telephone')"
                  class="#mb-6"
                  :label="__('Telefoonnummer')"
                  type="text"
                  @update:model-value="(value) => filterPhoneNumber(value)"
                />
              </div>
              <h2 class="#text-lg #font-semibold #mb-6 #mt-6">
                {{ __("Standaard bezorggegevens") }}
              </h2>
              <InputDropdown
                v-model="form.send_delivery_method"
                :items="deliveryMethods"
                :error="firstError('country')"
                :label="__('Levermethode')"
                class="#relative #max-w-[500px] #mb-6"
              />
              <div v-if="form.send_delivery_method !== 'international'">
                <!-- <InputDropdown v-model="form.send_country" :items="countries" :error="firstError('country')" :label="__('Land')" class="#max-w-[500px] #pb-6 #relative" /> -->
                <InputArea
                  v-model="form.send_address"
                  :label="__('Afleveradres')"
                  class="#mb-12 md:#mb-6"
                  :rows="4"
                />
              </div>
              <div
                ref="errorMessages"
                class="error-messages #fixed #bottom-6 max-md:#w-screen max-md:#px-8 #z-[1] #left-0 md:#sticky"
              >
                <div
                  v-if="messages.label"
                  class="imperfect-rectangle-mirrored #bg-brightcyan #mb-4 #flex #gap-x-4 #justify-between #items-center #px-6 #py-4 md:#sticky #bottom-6 #min-h-[70px]"
                >
                  <p
                    v-if="messages.label"
                    class="#text-white #font-semibold #text-sm #flex #items-center #gap-x-4"
                  >
                    <svg-use
                      id="circle-check"
                      type="solid"
                      class="#h-[1em] #w-[17px] #fill-white"
                    />
                    {{ messages.label }}
                  </p>
                  <div v-if="messages.label" class="#self-start #flex #items-center #gap-x-4">
                    <div
                      class="#relative #rounded-full #overflow-hidden #w-10 #h-2 #mt-2 #bg-inkdropdark"
                    >
                      <Transition appear name="timer">
                        <div v-if="messages.label" class="#bg-white #w-10 #h-2 #origin-left" />
                      </Transition>
                    </div>
                    <a
                      href="javascript:"
                      class="#text-[25px] #font-bold #leading-none #text-white"
                      @click="removeFlashMessage"
                      >x</a
                    >
                  </div>
                </div>
                <div
                  v-if="dirty || messages.details || Object.keys(formErrors).length > 0"
                  class="error-messages imperfect-rectangle #flex #gap-x-4 #justify-between #items-center #px-6 #py-4 md:#sticky #bottom-6 #min-h-[70px]"
                  :class="messageStyling"
                >
                  <p
                    v-if="messages.details && !dirty"
                    class="#text-white #font-semibold #text-sm #flex #items-center #gap-x-4"
                  >
                    <svg-use
                      id="circle-check"
                      type="solid"
                      class="#h-[1em] #w-[17px] #fill-white"
                    />
                    {{ messages.details }}
                  </p>
                  <p
                    v-if="Object.keys(formErrors).length > 0 && !dirty"
                    class="#text-white #font-semibold #text-sm #flex #items-center #gap-x-2"
                  >
                    <svg-use
                      id="circle-exclamation"
                      type="solid"
                      class="#h-[1em] #w-[17px] #fill-white"
                    />
                    {{ __("Een aantal ingevulde gegevens zijn niet juist.") }}
                  </p>
                  <p v-if="dirty" class="#text-white #font-semibold #text-sm #block">
                    {{ __("Je wijzigingen zijn nog niet opgeslagen.") }}
                  </p>
                  <Button
                    v-if="dirty"
                    btn-class="grey"
                    size="sm"
                    class="max-md:!#px-[12px] max-md:!#py-[6px]"
                    :disabled="submitting.details"
                    :text="__('Opslaan')"
                    @click="updateCustomer"
                  >
                  </Button>
                  <div
                    v-if="messages.details && !dirty"
                    class="#self-start #flex #items-center #gap-x-4"
                  >
                    <div
                      class="#relative #rounded-full #overflow-hidden #w-10 #h-2 #mt-2 #bg-inkdropdark"
                    >
                      <Transition appear name="timer">
                        <div
                          v-if="messages.details && !dirty"
                          class="#bg-white #w-10 #h-2 #origin-left"
                        />
                      </Transition>
                    </div>
                    <a
                      href="javascript:"
                      class="#text-[25px] #font-bold #leading-none #text-white"
                      @click="removeFlashMessage"
                      >x</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="#grow #basis-2/5 md:#pl-[20px] md:#max-w-[380px] md:#min-w-[335px]">
          <h2 class="#text-lg #font-semibold #mb-6">
            {{ __("Opties") }}
          </h2>
          <div class="#flex #flex-col #gap-y-10">
            <!-- <LabeledInput v-if="form.contact_type === 'bedrijf'" :title="__('Kostenplaats verplicht')" :popupTitle="__('Kostenplaats')" :popupMessage="__('Door het verplicht stellen van de kostenplaats kunt u nauwkeuriger en efficiënter uw kosten toewijzen en budgetten beheren.')" v-model="kostenplaats" /> -->

            <div
              v-if="
                (form.contact_type === 'bedrijf' && form.is_guest === false) ||
                isAdmin ||
                temp_account_payment
              "
              class=""
            >
              <div class="#group #flex #items-center #justify-between #gap-x-8 #w-full">
                <div
                  ref="orderonaccountContainer"
                  class="#flex #gap-x-4 #items-center #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
                >
                  <h3
                    class="#inline #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
                  >
                    {{ __("Bestellen op rekening") }}
                  </h3>
                  <div
                    v-if="infoItems.orderonaccount?.title && infoItems.orderonaccount?.info"
                    ref="orderonaccounticon"
                    class="fonticon"
                    @mouseenter="
                      () =>
                        openPopupNew(
                          'orderonaccountContainer',
                          infoItems.orderonaccount.title,
                          infoItems.orderonaccount.info,
                        )
                    "
                    @mouseleave="() => closePopupNew('orderonaccountContainer')"
                    @click="
                      () =>
                        stayPopup(
                          'orderonaccountContainer',
                          infoItems.orderonaccount.title,
                          infoItems.orderonaccount.info,
                        )
                    "
                  >
                    <svg-use
                      id="circle-info"
                      type="solid"
                      class="#h-[1em] #mt-2 md:#hidden md:group-hover:#block #block #transition-all hover:#fill-brightcyan #cursor-pointer #fill-[#a8a8a8] #w-8"
                    />
                  </div>
                </div>

                <div
                  v-if="pay_on_account >= 1"
                  class="#bg-emerald-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
                >
                  <p class="#text-sm #font-bold #text-[#2CD11A] #min-w-max">
                    {{ __("actief") }}
                  </p>
                </div>

                <div
                  v-else-if="pay_on_account === -1"
                  class="#bg-red-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
                >
                  <p class="#text-sm #font-bold #text-[#FF0000] #min-w-max">
                    {{ __("geblokkeerd") }}
                  </p>
                </div>

                <div
                  v-else-if="pay_on_account_requested"
                  class="#bg-amber-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
                >
                  <p class="#text-sm #font-bold #text-[#eb980c] #min-w-max">
                    {{ __("aangevraagd") }}
                  </p>
                </div>

                <div
                  v-else-if="temp_account_payment"
                  class="#bg-amber-100 #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
                >
                  <p class="#text-sm #font-bold #text-[#eb980c] #min-w-max">
                    {{ __("tijdelijk") }}
                  </p>
                </div>

                <InputToggle
                  v-else-if="pay_on_account === 0"
                  v-model="pay_on_account_requesting"
                  :disabled="submitting.pay_on_account"
                />

                <div
                  v-else
                  class="#bg-inkdroplight #rounded-full #flex #items-center #gap-x-3 #px-4 #py-1"
                >
                  <p class="#text-sm #font-bold #text-inkdropdark #min-w-max">
                    {{ __("niet actief") }}
                  </p>
                </div>
              </div>
              <SlideIn @enter="() => $refs.kvkNumber.focus()">
                <div
                  v-if="pay_on_account_requesting && !pay_on_account_requested"
                  class="#flex #items-start #gap-x-[15px] #my-[15px]"
                >
                  <InputField
                    ref="kvkNumber"
                    v-model="form.kvk_number"
                    :label="__('KVK nummer')"
                    type="text"
                    class="#w-full"
                    :error="firstError('kvk_number')"
                    :warning="formWarnings.kvk_number"
                  ></InputField>
                  <Button
                    :text="__('Versturen')"
                    btn-class="primary"
                    :class="{
                      '#opacity-50 #pointer-events-none': formWarnings.kvk_number || !form.company,
                    }"
                    @click="requestPayOnAccount()"
                  ></Button>
                </div>
              </SlideIn>
              <div
                v-if="messages.pay_on_account"
                ref="errorMessages"
                class="#min-h-[63px] max-md:#fixed max-md:#w-screen max-md:#left-0 max-md:#bottom-0 max-md:#bg-white max-md:#z-10 #flex #items-center #justify-start max-md:#justify-center max-md:#py-[20px] max-md:#shadow-focus"
              >
                <p
                  class="#text-center #text-sm md:#text-base md:#text-left #font-bold #text-[#2CD11A] md:#mt-6"
                >
                  {{ messages.pay_on_account }}
                </p>
              </div>
            </div>

            <LabeledInput
              v-model="form.keep_updated"
              :title="__('Nieuwsbrief ontvangen')"
              :error="firstError('keep_updated')"
              :popup="infoItems.newsletter"
            />

            <LabeledInput
              v-model="form.allow_sms"
              :title="__('SMS Notificaties')"
              :error="firstError('allow_sms')"
              :popup="infoItems.smsnotifications"
            />

            <div v-if="form.contact_type === 'bedrijf'">
              <!-- TODO Jelle: de error hiervan goed zetten -->
              <LabeledInput
                v-model="showInvoiceMail"
                :title="__('Apart factuur e-mailadres')"
                :popup="infoItems.billing"
              />

              <SlideIn @enter="() => $refs.invoiceMail.focus()">
                <InputField
                  v-if="showInvoiceMail"
                  id="invoiceMail"
                  ref="invoiceMail"
                  :model-value="form.email4invoice"
                  :error="firstError('email4invoice')"
                  class="#mb-6 #mt-6"
                  :label="__('E-mailadres')"
                  type="email"
                  @update:model-value="(value) => filterInvoiceEmailAddress(value)"
                />
              </SlideIn>
            </div>

            <div
              v-if="form.contact_type === 'bedrijf'"
              class="#group #flex #items-center #justify-between #gap-x-8 #w-full #relative"
            >
              <div
                ref="shippingContainer"
                class="#flex #gap-x-4 #items-center #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
              >
                <h3
                  class="#peer #inline #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap #cursor-default"
                >
                  {{ __("Verzendlabel logo") }}
                </h3>
                <div
                  v-if="infoItems.shippinglabel?.title && infoItems.shippinglabel?.info"
                  ref="shippingicon"
                  class="fonticon"
                  @mouseenter="
                    () =>
                      openPopupNew(
                        'shippingContainer',
                        infoItems.shippinglabel.title,
                        infoItems.shippinglabel.info,
                      )
                  "
                  @mouseleave="() => closePopupNew('shippingContainer')"
                  @click="
                    () =>
                      stayPopup(
                        'shippingContainer',
                        infoItems.shippinglabel.title,
                        infoItems.shippinglabel.info,
                      )
                  "
                >
                  <svg-use
                    id="circle-exclamation"
                    type="solid"
                    class="#h-[1em] #mt-2 md:#hidden md:group-hover:#block #block #transition-all hover:#fill-brightcyan #cursor-pointer #fill-[#a8a8a8] #w-8"
                  />
                </div>
              </div>
              <input
                ref="uploader"
                type="file"
                accept="image/png, image/jpeg"
                style="display: none"
                @change="uploadLabel"
              />
              <Button
                v-if="!shippingLabel"
                btn-class="grey"
                :disabled="submitting.label"
                :text="__('Uploaden')"
                @click="$refs.uploader.click()"
              >
              </Button>
              <Button
                v-else
                btn-class="grey"
                :disabled="submitting.label"
                :text="__('Verwijderen')"
                @click="deleteLabel"
              >
              </Button>
            </div>

            <p
              v-if="messages.label"
              class="#text-left #text-sm md:#text-base #font-semibold #text-[#2CD11A]"
            >
              {{ messages.label }}
            </p>

            <p
              v-if="hasError('shipping_label_logo')"
              class="#text-left #text-[#d9534f] #text-sm #font-semibold"
            >
              {{ firstError("shipping_label_logo") }}
            </p>
            <img
              v-show="form.contact_type === 'bedrijf' && shippingLabel"
              style="width: 100%; max-height: 100px; object-fit: contain; object-position: left"
              :src="shippingLabel"
            />
            <div class="#group #flex #items-center #justify-between #gap-x-8 #w-full">
              <div
                ref="papersampleContainer"
                class="#flex #gap-x-4 #items-center #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
              >
                <h3
                  class="#inline #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
                >
                  {{ __("Papiermonsterboek") }}
                </h3>
                <div
                  v-if="infoItems.papersample?.title && infoItems.papersample?.info"
                  ref="papersampleicon"
                  class="fonticon"
                  @mouseenter="
                    () =>
                      openPopupNew(
                        'papersampleContainer',
                        infoItems.papersample.title,
                        infoItems.papersample.info,
                      )
                  "
                  @mouseleave="() => closePopupNew('papersampleContainer')"
                  @click="
                    () =>
                      stayPopup(
                        'papersampleContainer',
                        infoItems.papersample.title,
                        infoItems.papersample.info,
                      )
                  "
                >
                  <svg-use
                    id="circle-exclamation"
                    type="solid"
                    class="#h-[1em] #mt-2 md:#hidden md:group-hover:#block #block #transition-all hover:#fill-brightcyan #cursor-pointer #fill-[#a8a8a8] #w-8"
                  />
                </div>
              </div>
              <Button
                btn-class="grey"
                :disabled="submitting.sample"
                :text="__('Aanvragen')"
                @click="paperSamples"
              >
              </Button>
            </div>
            <div class="#flex #items-center #justify-between #gap-x-8 #w-full">
              <h3
                class="#inline #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
              >
                {{ __("Wachtwoord") }}
              </h3>
              <Button
                btn-class="grey"
                :disabled="submitting.password"
                :text="__('Wijzigen')"
                @click="changePassword"
              >
              </Button>
            </div>

            <p
              v-if="messages.password"
              class="#text-left #text-sm md:#text-base #font-semibold #text-[#2CD11A]"
            >
              {{ messages.password }}
            </p>

            <p
              v-if="hasError('send_delivery_method')"
              class="#text-left #text-[#d9534f] #text-sm #font-semibold"
            >
              {{ firstError("send_delivery_method") }}
            </p>

            <div class="#flex #items-center #justify-between #gap-x-8 #w-full">
              <h3
                class="#inline #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
              >
                {{ __("Voorkeurstaal") }}
              </h3>
              <InputDropdown
                v-model="form.preferred_locale"
                :items="locales"
                :error="firstError('country')"
              />
            </div>

            <p
              v-if="hasError('preferred_locale')"
              class="#text-left #text-[#d9534f] #text-sm #font-semibold"
            >
              {{ firstError("preferred_locale") }}
            </p>
            <div class="#flex #flex-col #items-end #gap-y-4">
              <div class="#flex #items-center #justify-between #gap-x-8 #w-full">
                <h3
                  class="#inline #break-words #overflow-y-visible #overflow-x-clip #overflow-ellipsis #whitespace-nowrap"
                >
                  {{ __("Bestanden verwijderen") }}
                </h3>
                <InputDropdown v-model="delete_files" :items="deleteFilesPeriods"></InputDropdown>
              </div>
              <Button
                v-if="delete_files"
                btn-class="secondary"
                :disabled="submitting.password"
                :text="__('Verwijderen')"
                @click="deleteFiles"
              >
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FadeIn>
  <InfoPopup />
</template>

<script>
import { lang, form, autoComplete, mobile } from "../mixins";
import { debounce } from "../helpers";
import FadeIn from "../components/FadeIn.vue";
import SlideIn from "../components/SlideIn.vue";
import InfoPopup from "../components/InfoPopup.vue";
import InputField from "../components/InputField.vue";
import InputArea from "../components/InputArea.vue";
import InputToggle from "../components/InputToggle.vue";
import LabeledInput from "../components/LabeledInput.vue";
import Button from "../components/Button.vue";
import InputDropdown from "../components/InputDropdown.vue";
import InfoDropdown from "../components/InfoDropdown.vue";
import LoaderSpinner from "../components/LoaderSpinner.vue";
import SvgUse from "../components/SvgUse.vue";
import { mapGetters } from "vuex";
import { toRaw } from "vue";
import { removeSpaces, removeLetters, removeSpecialCharacters } from "../helpers.js";

export default {
  components: {
    FadeIn,
    SlideIn,
    InputField,
    InputArea,
    InputToggle,
    Button,
    InputDropdown,
    LabeledInput,
    InfoPopup,
    InfoDropdown,
    SvgUse,
    LoaderSpinner,
  },
  mixins: [lang, form, autoComplete, mobile],
  data() {
    return {
      payLater: "off",
      infoItems: [],
      dirty: false,
      deleteCompany: false,
      showInvoiceMail: false,
      initialType: null,
      vatNumberError: false,
      initialForm: null,
      popupHover: {
        shippingicon: false,
      },
      popupOpen: {
        shippingicon: false,
      },
      icon: null,
      countries: {},
      countriesForSelect: {},
      deliveryMethods: {},
      shippingLabel: null,
      pay_on_account: this.$store.getters.user.pay_on_account || 0,
      pay_on_account_requesting: false,
      pay_on_account_requested: this.$store.getters.user.pay_on_account_requested || false,
      temp_account_payment: this.$store.getters.user.temp_account_payment || false,
      flashTimer: null,
      vatResponse: null,
      submitting: {
        details: false,
        label: false,
        password: false,
        pay_on_account: false,
      },
      messages: {
        details: null,
        label: null,
        password: null,
        pay_on_account: null,
      },
      form: {
        company: this.$store.getters.user.company || "",
        vat_number: this.$store.getters.user.vat_number || "",
        contact_type: this.$store.getters.user.type || "",
        name_contact: this.$store.getters.user.name || "",
        email: this.$store.getters.user.email || "",
        telephone: this.$store.getters.user.telephone || "",
        zipcode: this.$store.getters.user.zipcode || "",
        city: this.$store.getters.user.city || "",
        street: this.$store.getters.user.street || "",
        streetnumber: this.$store.getters.user.streetnumber || "",
        country: this.$store.getters.user.country || "",
        email4invoice: this.$store.getters.user.email4invoice || "",
        send_delivery_method: this.$store.getters.user.send_delivery_method || "-",
        allow_sms: this.$store.getters.user.allow_sms || false,
        keep_updated: this.$store.getters.user.keep_updated || false,
        preferred_locale: this.$store.getters.user.preferred_locale || "nl",
        send_address: this.$store.getters.user.send_address || "",
        // TODO(Jelle): deze werkend maken zodat ie ook werkt voor het bestelformulier
        send_country: "",
        is_guest: this.$store.getters.user.is_guest === false ? false : true,
        kvk_number: "",
      },
      deleteFilesPeriods: {
        all: "Alles",
        one_month: "Ouder dan 1 maand",
        one_year: "Ouder dan 1 jaar",
        two_years: "Ouder dan 2 jaar",
      },
      delete_files: null,
      formWarnings: {
        company: null,
        kvk_number: null,
      },
    };
  },
  computed: {
    ...mapGetters(["route", "user", "isAdmin", "config"]),
    messageStyling() {
      return {
        "#bg-vividmagenta": this.dirty === true,
        "#bg-brightcyan": this.messages.details || this.messages.label,
        "!#bg-[#d9534f]": Object.keys(this.formErrors).length > 0 && this.dirty === false,
      };
    },
    locales() {
      let locales = {};

      for (const [key, value] of Object.entries(this.config("app.locales"))) {
        locales[key] = value["language"];
      }

      return locales;
    },
    vies_text() {
      return {
        "#text-[#EB980C]":
          this.vatResponse && this.vatResponse.error_text && this.vatResponse.allow_save,
        "#text-[#d9534f]":
          this.vatResponse && this.vatResponse.error_text && !this.vatResponse.allow_save,
      };
    },
    vies_border() {
      return {
        "!#border-[#EB980C]":
          this.vatResponse && this.vatResponse.error_text && this.vatResponse.allow_save,
        "!#border-[#d9534f]":
          this.vatResponse && this.vatResponse.error_text && !this.vatResponse.allow_save,
      };
    },
    from_europe() {
      if (Object.keys(this.countries).length > 0 && this.form.country) {
        return this.countries[this.form.country].from_eu;
      }

      if (Object.keys(this.countries).length > 0 && !this.form.country) {
        return false;
      }

      return true;
    },
  },
  watch: {
    showInvoiceMail(newValue) {
      if (newValue) {
        this.form.email4invoice = this.user.email4invoice || "";
      } else {
        this.form.email4invoice = "";
      }
    },
    "form.contact_type"(newValue, oldValue) {
      if (oldValue === "bedrijf" && newValue !== "bedrijf") {
        this.deleteCompany = true;
      } else {
        this.deleteCompany = false;
      }
      this.initialType = null;
    },
    "form.keep_updated"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateCustomer();
      }
    },
    "form.allow_sms"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateCustomer();
      }
    },
    "form.zipcode"(newValue) {
      this.autocompleteAddress(newValue, this.form.country);
    },
    "form.country"(newValue) {
      if (newValue && this.form.vat_number) {
        this.validateVatNumber(this.form.vat_number);
      }
    },
    "form.vat_number"() {
      this.vatResponse = null;
    },
    "formErrors.vat_number"(newValue) {
      if (newValue) {
        this.validateVatNumber(this.form.vat_number);
      }
    },
    "form.company"(newValue) {
      if (
        newValue !== this.initialForm.company &&
        (this.pay_on_account === 1 ||
          (this.pay_on_account_requested && this.pay_on_account === 0)) &&
        !this.deleteCompany
      ) {
        this.formWarnings.company = this.__(
          'Als je de bedrijfsnaam aanpast, zal je de betaalmethode "Bestellen op rekening" opnieuw moeten aanvragen.',
        );
      } else if (newValue !== this.initialForm.company && this.form.kvk_number) {
        this.formWarnings.kvk_number = this.__(
          "Je moet eerst de nieuwe bedrijfsnaam opslaan voordat je een KVK nummer kan opslaan.",
        );
      } else {
        this.formWarnings.company = null;
        this.formWarnings.kvk_number = null;
      }
    },
    "form.kvk_number"(newValue) {
      if (
        newValue !== this.initialForm.kvk_number &&
        this.form.company !== this.initialForm.company
      ) {
        this.formWarnings.kvk_number = this.__(
          "Je moet eerst de nieuwe bedrijfsnaam opslaan voordat je een KVK nummer kan opslaan.",
        );
      } else {
        this.formWarnings.kvk_number = null;
      }
    },
  },
  mounted() {
    if (this.form.vat_number && this.form.contact_type === "bedrijf") {
      this.filterVatNumber(this.form.vat_number);
      this.validateVatNumber(this.form.vat_number);
    }
    this.initialType = this.form.contact_type;
    this.initialForm = JSON.parse(JSON.stringify(toRaw(this.form)));
    document.addEventListener("mousedown", this.hidePopup);
  },
  beforeUnmount() {
    document.removeEventListener("mousedown", this.hidePopup);
  },
  created() {
    this.showInvoiceMail = this.user.email4invoice ? true : false;
    this.getCountries();
    this.getDeliveryMethods();
    this.getShippingLabel();
    this.getInfoItems();

    for (const [key] of Object.entries(this.form)) {
      this.$watch(`form.${key}`, (newValue, oldValue) => {
        if (key === "kvk_number") {
          return;
        }
        this.onFormChange(key, newValue, oldValue);
      });
    }
  },
  methods: {
    async getInfoItems() {
      const response = await fetch(this.route("get_info_items"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          items: [
            "newsletter",
            "smsnotifications",
            "billing",
            "shippinglabel",
            "papersample",
            "orderonaccount",
          ],
        }),
      });
      if (response.ok) {
        this.infoItems = await response.json();
      } else {
        await response.text();
      }
    },
    async getCountries() {
      let response = await fetch(this.route("countries"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        this.countries = await response.json();
        for (const [key, value] of Object.entries(this.countries)) {
          this.countriesForSelect[key] = value.label;
        }
      } else {
        await response.text();
      }
    },
    async getDeliveryMethods() {
      let response = await fetch(this.route("delivery_methods"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        this.deliveryMethods = await response.json();
      } else {
        await response.text();
      }
    },
    async getShippingLabel() {
      let response = await fetch(this.route("shipping_label"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.link) {
          this.shippingLabel = data.link;
        } else {
          this.shippingLabel = null;
        }
      } else {
        await response.text();
      }
    },
    async deleteLabel() {
      this.submitting.label = true;

      let response = await fetch(this.route("form.remove_shipping_label_logo"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.message) {
          await this.getShippingLabel();
          this.flashMessage("label", data.message);
        }
        this.submitting.label = false;
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting.label = false;
      } else {
        await response.text();
      }
    },
    async uploadLabel() {
      this.submitting.label = true;
      this.clearAllErrors();

      var data = new FormData();
      data.append("shipping_label_logo", this.$refs.uploader.files[0]);

      let response = await fetch(this.route("form.upload_shipping_label_logo"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: data,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.message) {
          await this.flashMessage("label", data.message);
          this.getShippingLabel();
        }
        this.submitting.label = false;
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting.label = false;
        this.setIntercomIconLocation();
      } else {
        await response.text();
      }
    },
    async requestPayOnAccount() {
      this.submitting.pay_on_account = true;
      this.clearAllErrors();

      let response = await fetch(this.route("form.pay_on_account"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({ kvk_number: this.form.kvk_number }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.message) {
          await this.flashMessage("pay_on_account", data.message);
          this.form.kvk_number = null;
          this.pay_on_account_requested = true;
        }
        this.submitting.pay_on_account = false;
        this.updateCustomer();
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting.labepay_on_accountl = false;
      } else {
        await response.text();
      }
    },
    async changePassword() {
      this.submitting.password = true;
      this.clearAllErrors();

      let response = await fetch(this.route("password.request"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          email: this.user.email,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.message) {
          this.flashMessage("password", data.message);
        }
        this.submitting.password = false;
      } else if (response.status === 422) {
        const body = await response.json();
        this.formErrors = body.errors;
        this.submitting.password = false;
      } else {
        await response.text();
      }
    },
    async updateCustomer() {
      this.submitting.details = true;
      this.clearAllErrors();

      if (this.deleteCompany === true) {
        this.form.company = "";
        this.form.vat_number = "";
        this.form.email4invoice = "";
        this.showInvoiceMail = false;
        this.form.kvk_number = null;
        this.pay_on_account = 0;
        this.pay_on_account_requesting = false;
        this.pay_on_account_requested = false;
      }

      if (this.form.contact_type === "bedrijf" && !this.from_europe) {
        this.form.vat_number = "";
      }

      let response = await fetch(this.route("profile"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify(this.form),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success && data.message) {
          setTimeout(() => {
            if (
              this.formWarnings.company &&
              (this.pay_on_account === 1 || this.pay_on_account_requested)
            ) {
              this.pay_on_account = 0;
              this.pay_on_account_requested = false;
              this.pay_on_account_requesting = false;
              this.formWarnings.company = null;
              // this.form.kvk_number = null;
            } else if (this.formWarnings.kvk_number && this.form.kvk_number) {
              this.formWarnings.kvk_number = null;
            }
            this.flashMessage("details", data.message);
            this.submitting.details = false;
            this.dirty = false;
            this.initialForm = JSON.parse(JSON.stringify(toRaw(this.form)));
          }, 500);
        }
      } else if (response.status === 422) {
        const body = await response.json();
        this.dirty = false;
        this.formErrors = body.errors;
        this.submitting.details = false;
      } else {
        await response.text();
      }
    },
    async deleteFiles() {
      let response = await fetch(this.route("profile.files.delete"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({ type: this.delete_files }),
      });

      response = await response.json();

      if (response.success) {
        setTimeout(() => {
          this.flashMessage("details", response.message);
        }, 500);
      }
    },
    async validateVatNumber(value) {
      let response = await fetch(
        this.route("vat_number", { country: this.form.country, vatNumber: value }),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        },
      );

      if (response.ok) {
        this.vatResponse = await response.json();
      }
    },
    onFormChange(key, newValue, oldValue) {
      this.dirty = JSON.stringify(this.form) !== JSON.stringify(this.initialForm);
      this.setIntercomIconLocation();
      this.clearErrors(key);
      this.removeFlashMessage();
    },
    updateCustomerDebounced: debounce(async function () {
      await this.updateCustomer();
    }, 100),
    paperSamples() {
      window.open(this.route("legacy", { "path?": "article?product=samples" }), "_blank");
    },
    flashMessage(type, message) {
      this.messages[type] = message;
      this.setIntercomIconLocation();
      this.flashTimer = setTimeout(() => {
        this.messages[type] = null;
        this.setIntercomIconLocation();
      }, 4000);
    },
    setIntercomIconLocation() {
      this.$nextTick(() => {
        if (this.$refs.errorMessages && this.isMobile) {
          let value = this.$refs.errorMessages.offsetHeight + 25;
          this.emitter.emit("set-intercom-location", value);
        }
        if (this.$refs.errorMessages.offsetHeight === 0) {
          this.emitter.emit("set-intercom-location", 20);
        }
      });
    },
    removeFlashMessage() {
      for (const [key] of Object.entries(this.messages)) {
        this.messages[key] = null;
      }
      clearTimeout(this.flashTimer);
      this.setIntercomIconLocation();
    },
    hidePopup(event) {
      const hasDesiredParent = event.target.closest(".fonticon");
      const hasDesiredPopup = event.target.closest(".infoPopup");

      if (hasDesiredParent === null && hasDesiredPopup === null) {
        this.emitter.emit("removestay-info-popup");
      }
    },
    closePopup(event, icon) {
      this.popupHover[icon] = true;
    },
    openPopup(icon) {
      this.popupOpen[icon] = true;
    },
    openPopupNew(element, title, info) {
      this.emitter.emit("open-info-popup", {
        title: title,
        info: info,
        placement: "left",
        element: this.$refs[element],
      });
    },
    closePopupNew() {
      this.emitter.emit("close-info-popup");
    },
    stayPopup(element, title, info) {
      this.emitter.emit("stay-info-popup", {
        title: title,
        info: info,
        placement: "left",
        element: this.$refs[element],
      });
    },
    getCircleClass(type) {
      return {
        begincircle: this.initialType === type,
        circletype: this.form.contact_type === type,
      };
    },
    filterPhoneNumber(value) {
      value = removeLetters(value);
      value = removeSpaces(value);

      this.form.telephone = value;
    },
    filterEmailAddress(value) {
      value = removeSpaces(value);

      this.form.email = value;
    },
    filterInvoiceEmailAddress(value) {
      value = removeSpaces(value);
      this.form.email4invoice = value;
    },
    filterVatNumber(value) {
      value = removeSpaces(value);
      value = removeSpecialCharacters(value);

      this.form.vat_number = value.toUpperCase();
    },
    filterZipCode(value) {
      value = removeSpaces(value);
      value = removeSpecialCharacters(value);

      this.form.zipcode = value.toUpperCase();
    },
  },
};
</script>
