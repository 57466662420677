<template>
  <div>
    <a
      href="javascript:;"
      class="#group #flex #justify-between #w-full #text-sm"
      @click="show = !show"
    >
      <span
        class="#w-max #border-b #border-dotted #border-current group-hover:#border-vividmagenta group-hover:#text-vividmagenta"
      >
        {{ __("Factuuropties") }}
      </span>
      <svg-use
        id="angle-down"
        type="solid"
        class="#blcok #w-[0.9em] #h-[0.9em] #mt-[2px] #fill-inkdropdark #cursor-pointer group-hover:#fill-vividmagenta"
        :class="{ '#rotate-180': show }"
      />
    </a>
    <div
      class="#grid #transition-all #overflow-hidden"
      :class="show ? '#grid-rows-[1fr]' : '#grid-rows-[0fr]'"
    >
      <div
        class="#flex #flex-col #items-start #justify-between #gap-y-[10px] #overflow-hidden #pt-2"
      >
        <InputField
          :model-value="reference"
          class="#w-full #pt-4"
          :label="__('Referentie / kostenplaats')"
          type="text"
          @update:model-value="(value) => $emit('update:reference', value)"
        />
        <InputField
          :model-value="copy"
          class="#w-full"
          :label="__('Stuur factuur alleen naar')"
          type="text"
          @update:model-value="(value) => $emit('update:copy', value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { lang } from "../mixins";
import SvgUse from "../components/SvgUse.vue";
import Button from "../components/Button.vue";
import InputField from "./InputField.vue";

export default {
  components: {
    SvgUse,
    Button,
    InputField,
  },
  mixins: [lang],
  props: {
    error: {
      type: String,
      required: false,
      default: "",
    },
    reference: {
      type: String,
      default: "",
    },
    copy: {
      type: String,
      default: "",
    },
  },
  emits: ["update:reference", "update:copy", "submit"],
  data() {
    return {
      show: false,
    };
  },
};
</script>
